export default function () {
  const { itemsPerPage } = useAppConfig()
  const route = useRoute()

  const page = computed<number>(() =>
    route.query?.strona
      ? Number(route.query.strona)
      : 1,
  )

  const to = (page: number) => ({
    path: route.path,
    query: {
      ...route.query,
      strona: page,
    },
  })

  const offset = computed<number>(() => itemsPerPage * (page.value - 1))

  return {
    to,
    page,
    offset,
    itemsPerPage,
  }
}
